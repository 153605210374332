// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.styles__DfinityLogo___Y4TKx {
  height: 22px;
  width: 42px;
}
@media screen and (max-width: 992px) {
  .styles__DfinityLogo___Y4TKx {
    height: 15px;
    width: 32px;
  }
}

.styles__dateTimeContainer___xyeqd {
  padding: 12px;
  box-sizing: border-box;
  width: 100%;
}

.styles__submittedDateTime___rpk41 {
  padding: 6px;
  box-sizing: border-box;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 4px;
  background-color: #FAF9FA;
}`, "",{"version":3,"sources":["webpack://./app/common.scss","webpack://./app/components/app/poh/ReservedPohButton/styles.scss"],"names":[],"mappings":"AA0CA;EACE,YAAA;EACA,WAAA;ACzCF;AD2CE;EAJF;IAKI,YAAA;IACA,WAAA;ECxCF;AACF;;AAPA;EACE,aAAA;EACA,sBAAA;EAEA,WAAA;AASF;;AANA;EACE,YAAA;EACA,sBAAA;EAEA,WAAA;EACA,aAAA;EACA,mBAAA;EACA,uBAAA;EAEA,kBAAA;EACA,yBDUQ;ACHV","sourcesContent":["$secondary-text-color: #818a94;\n$horizontal-line-color: #3e4348;\n$card-background: #212121;\n$highlighted-text: #c91988;\n\n$button-background-color: #3d52fa;\n$button-background-hover-color: #5769f5;\n$button-foreground-color: #fff;\n$dark-button-border-color: #818a94;\n\n// .horizontal-line {\n//   height: 1px;\n//   width: 100%;\n//   border-bottom: 1px solid $horizontal-line-color;\n// }\n\n$primary: #3d52fa;\n$primary-hover: #5769f5;\n$secondary: #c91988;\n$success: #0fb36c;\n$white: #fff;\n$light: #c4c4c4;\n$silver: #818a94;\n$dark: #212121;\n$black: #121212;\n\n// Redesign styles\n$grey-01: #EFEFEF;\n$grey-03: #F4F4F4;\n$grey-04: #FAF9FA;\n$grey-05: #F7F7F7;\n$green-01: #1B4444;\n$green-02: #325757;\n$green-05: #8DA1A1;\n$green-03: #496969;\n$green-08: #D1DADA;\n$green-10: #E8ECEC;\n$green-11: #F4F6F6;\n$light-green-01: #66DD95;\n$light-green-03: #94E7B5;\n$light-green-09: #f0fcf4;\n\n.DfinityLogo {\n  height: 22px;\n  width: 42px;\n\n  @media screen and (max-width: 992px) {\n    height: 15px;\n    width: 32px;\n  }\n}\n","@import '../../../../common.scss';\n\n.dateTimeContainer {\n  padding: 12px;\n  box-sizing: border-box;\n\n  width: 100%;\n}\n\n.submittedDateTime {\n  padding: 6px;\n  box-sizing: border-box;\n\n  width: 100%;\n  display: flex;\n  align-items: center;\n  justify-content: center;\n  \n  border-radius: 4px;\n  background-color: $grey-04;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"DfinityLogo": `styles__DfinityLogo___Y4TKx`,
	"dateTimeContainer": `styles__dateTimeContainer___xyeqd`,
	"submittedDateTime": `styles__submittedDateTime___rpk41`
};
export default ___CSS_LOADER_EXPORT___;
